import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { BillPage } from "./../pages/bill";
import { CartPage } from "./../pages/cart";
import { HomePage } from "./../pages/home";
import { MenuPage } from "./../pages/menu";
import { MenuDetailPage } from "./../pages/menu-detail";
import { PaymentPage } from "./../pages/payment";

export default function App45CoffeeQR() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:customer/:level/:idTable" element={<HomePage />} />
        <Route path="/:customer/:level/:idTable/menu" element={<MenuPage />} />
        <Route
          path="/:customer/:level/:idTable/menu/:id"
          element={<MenuDetailPage />}
        />
        <Route path="/:customer/:level/:idTable/cart" element={<CartPage />} />
        <Route
          path="/:customer/:level/:idTable/payment/:idMember/"
          element={<PaymentPage />}
        />
        <Route path="/:customer/:level/:idTable/bill" element={<BillPage />} />
        <Route path="*" element={<Navigate to="/guest/1/1" />} />
      </Routes>
    </BrowserRouter>
  );
}
