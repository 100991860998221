import App45Coffee from "./module-app/45coffee";
import App45CoffeeQR from "./module-app/45coffee-qr";
import AppAmstirdamCoffee from "./module-app/amstirdam-coffee";
import AppAworjiwaCoffee from "./module-app/aworjiwa-coffee";
import AppBonBonKahve from "./module-app/bon-bon-kahve";
import AppCafetariaUnairB from "./module-app/cafetaria-unair-b";
import AppDemoDismore from "./module-app/demo-dismore";
import AppDemoDismoreQR from "./module-app/demo-dismore-qr";
import AppGymale from "./module-app/gymale";
import AppMagicBilliard from "./module-app/magic-billiard";
import AppTebuTerusGancit from "./module-app/tebu-terus-gancit";
import AppTebuTerusPsrebo from "./module-app/tebu-terus-psrebo";

const ClientRouter = {
  "demo-dismore": <AppDemoDismore />,
  "demo-dismore-qr": <AppDemoDismoreQR />,
  "45coffee": <App45Coffee />,
  "45coffee-qr": <App45CoffeeQR />,
  "cafetaria-unair-b": <AppCafetariaUnairB />,
  "aworjiwa-coffee": <AppAworjiwaCoffee />,
  "amstirdam-coffee": <AppAmstirdamCoffee />,
  "bon-bon-kahve": <AppBonBonKahve />,
  gymale: <AppGymale />,
  "magic-billiard": <AppMagicBilliard />,
  "tebu-terus-psrebo": <AppTebuTerusPsrebo />,
  "tebu-terus-gancit": <AppTebuTerusGancit />
};

function App() {
  return <div>{ClientRouter[process.env.REACT_APP_CLIENT_ID]}</div>;
}

export default App;
